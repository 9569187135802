<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="URL" prop="tweet_url">
        <el-input
          v-model="form.tweet_url"
          placeholder="请输入推文RUL"
        ></el-input>
      </el-form-item>
      <el-form-item label="举报时间">
        <el-date-picker
          style="width: 100%;"
          v-model="form.report_time"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
        <!-- <span>说明：举报时间为空时则立即发表</span> -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addReportTask } from '@/api/twitter'
import { hanleTimeDelay } from '@/utils/validate'
import { pickerOptions, getAfterTime } from '@/utils/dateTimePicker'

export default {
  name: 'LikeTask',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  props: {
    accountId: Number
  },
  data() {
    // 这里存放数据",
    return {
      pickerOptions,
      form: {
        account: this.accountId,
        report_time: '',
        tweet_url: ''
      },
      rules: {
        tweet_url: [
          {
            required: true,
            message: '推文链接不能为空',
            trigger: 'blur'
          },
          {
            pattern: /^https:\/\/(twitter|x).com\/[a-zA-Z0-9_]{5,15}\/status\/[0-9]+$/,
            message: '推文URL格式不正确'
          }
        ],
        report_time: [
          {
            trigger: 'blur',
            message: '不能小于当前时间',
            validator: this.hanleTimeDelay
          }
        ]
      }
    }
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 方法集合",
  methods: {
    hanleTimeDelay,
    async onSubmit() {
      console.log('submit!')
      await this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.account = this.accountId
          let obj = { ...this.form }
          if (!obj.report_time) {
            delete obj.report_time
          }
          await addReportTask(obj).then(resp => {
            this.$refs.form.resetFields()
            this.form.report_time = null
            this.cancel()
          })
        } else {
          return false
        }
      })
    },
    cancel() {
      this.$emit('dialogStatus', false)
    },
    setDefaultTime() {
      this.form.report_time = getAfterTime(10 * 60)
    }
  },
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {},

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    // this.setDefaultTime()
    console.log('likeTask created')
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
    console.log('likeTask mounted')
  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {},
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {},
  // 生命周期 - 销毁之前",
  beforeDestroy() {},
  destroyed() {},
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {}
}

// "http-get请求": {
//   "prefix": "httpget",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'get',",
//     "params: this.\\$http.adornParams({})",
//     "}).then(({ data }) => {",
//     "})"
//   ],
//     "description": "httpGET请求"
// },
// "http-post请求": {
//   "prefix": "httppost",
//     "body": [
//     "this.\({",
//     "url: this.\\$http.adornUrl(''),",
//     "method: 'post',",
//     "data: this.\\$http.adornData(data, false)",
//     "}).then(({ data }) => { });"
//   ],
//     "description": "httpPOST请求"
// }
// }
</script>

<style></style>
