<template>
  <div class="app-container">
    <el-form
      ref="forwardTaskForm"
      :model="forwardTaskForm"
      :rules="forwardTaskFormRules"
      label-width="80px"
    >
      <el-form-item label="推文链接" prop="tweet_url">
        <el-input
          v-model="forwardTaskForm.tweet_url"
          placeholder="请输入推文的url"
        ></el-input>
      </el-form-item>
      <el-form-item label="转帖评论" prop="comment">
        <el-input
          v-model="forwardTaskForm.comment"
          placeholder="请输入转帖时的评论"
        ></el-input>
      </el-form-item>
      <el-form-item label="转发时间" prop="forward_time">
        <el-date-picker
          v-model="forwardTaskForm.forward_time"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
        <span>说明：转发时间为空时则立即发表</span>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="forwardTaskFormSubmit"
          :loading="forwardTaskFormLoading"
        >
          立即创建
        </el-button>
        <el-button @click="closeForwardTaskDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { hanleTimeDelay } from '@/utils/validate'
import { pickerOptions, getAfterTime } from '@/utils/dateTimePicker'
import { addForwardTask } from '@/api/twitter'

export default {
  name: 'ForwardTask',
  props: { accountId: Number },
  data() {
    return {
      forwardTaskForm: {
        account: this.accountId,
        tweet_url: '',
        comment: '',
        forward_time: null
      },
      forwardTaskFormRules: {
        tweet_url: [
          {
            required: true,
            message: '推文链接不能为空',
            trigger: 'blur'
          },
          {
            pattern: /^https:\/\/(twitter|x).com\/[a-zA-Z0-9_]{5,15}\/status\/[0-9]+$/,
            message: '推文URL格式不正确'
          }
        ],
        forward_time: [
          {
            trigger: 'blur',
            message: '不能小于当前时间',
            validator: this.hanleTimeDelay
          }
        ]
      },
      forwardTaskFormLoading: false,
      pickerOptions: pickerOptions
    }
  },
  methods: {
    hanleTimeDelay,
    getAfterTime,
    async forwardTaskFormSubmit() {
      await this.$refs.forwardTaskForm.validate(async valid => {
        if (valid) {
          this.forwardTaskFormLoading = true
          this.forwardTaskForm.account = this.accountId
          await addForwardTask(this.forwardTaskForm)
          this.forwardTaskFormLoading = false
          this.closeForwardTaskDialog()
        } else {
          return false
        }
      })
    },
    closeForwardTaskDialog() {
      this.$refs.forwardTaskForm.resetFields()
      this.forwardTaskForm.forward_time = null
      this.forwardTaskDialogShow = false
      this.$emit('dialogStatus', false)
    },
    setDefaultTime() {
      this.forwardTaskForm.forward_time = getAfterTime(10 * 60)
    }
  },
  created() {
    // this.setDefaultTime()
  }
}
</script>
