<template>
  <div>
    <el-form
      ref="postTaskForm"
      :model="postTaskForm"
      :rules="postTaskFormRules"
      label-width="80px"
      v-loading="uploadLoading"
    >
      <el-form-item
        label="发帖内容"
        prop="content"
        style="white-space: nowrap; "
      >
        <el-input
          style="width: calc(100% - 130px);"
          type="textarea"
          :rows="4"
          placeholder="请输入推文"
          v-model="postTaskForm.content"
        ></el-input>
        <el-button
          size="mini"
          style="margin-left: 20px; "
          type="primary"
          @click="openTranslate"
        >
          选择语料
        </el-button>
        <translatePage ref="translatePageRef" @languageFun="languageFun" />
      </el-form-item>
      <el-form-item label="风险类型" v-if="riskSelectShow">
        <el-tooltip
          content="忽视帐号被封禁的风险,继续使用文字发表。此选项会使发帖帐号变为风险状态,若后续账号多次被封禁，视情况不再免费补发账号"
          placement="top"
        >
          <el-radio v-model="postTaskForm.risk_type" label="1">
            直接发表内容
          </el-radio>
        </el-tooltip>
        <el-tooltip
          content="规避帐号被封禁的风险,将文字内容转为一张图片发送。此选项不会影响发帖帐号的风险状态。"
          placement="top"
        >
          <el-radio v-model="postTaskForm.risk_type" label="2">
            转为图片发表
          </el-radio>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="素材选择" prop="media">
        <!-- <MaterialSelect
          v-on:getChildMaterial="getChildMaterial"
        ></MaterialSelect> -->
        <MaterialPage @getChildMaterial="getChildMaterial" />
      </el-form-item>
      <el-form-item label="发表时间" prop="publish_time">
        <el-date-picker
          v-model="postTaskForm.publish_time"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <span>说明：</span>
        <br />
        <span>1.发表时间为空时系统自动选择最近的可发帖时间</span>
        <br />
        <span>2.单个帐号两次发帖间隔至少3分钟</span>
        <br />
        <span>3.单个帐号发帖频率最高为10篇/小时;50篇/天。演示帐号:5篇/天</span>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="postTaskFormSubmit"
          :loading="postTaskFormLoading"
        >
          立即创建
        </el-button>
        <el-button @click="closePostTaskDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { hanleTimeDelay } from '@/utils/validate'
import { verifyTextLength } from '@/utils/verifyText'
import { pickerOptions, getAfterTime } from '@/utils/dateTimePicker'
import { addPostTask, getAllRiskKeyword } from '@/api/twitter'
import MaterialPage from '@/components/material/MaterialPage'

export default {
  name: 'PostTask',
  props: { accountId: Number },
  components: { MaterialPage },
  data() {
    return {
      uploadLoading: false,
      // 风险任务类型选项开关
      riskSelectShow: false,
      // 风险关键词列表
      riskKeywords: [],
      // 检测到的风险关键词
      riskKeyword: '',
      postTaskForm: {
        content: '',
        account: '',
        publish_time: null,
        media: null,
        // mediaId: null,
        mediaMaterial: null,
        risk_type: 0
      },
      postTaskFormRules: {
        content: [
          {
            trigger: 'blur',
            validator: this.checkContentRisk
          },
          {
            trigger: 'blur',
            validator: this.checkContentAndMedia
          }
        ],
        media: [
          {
            trigger: 'blur',
            validator: this.checkContentAndMedia
          },
          {
            trigger: 'change',
            validator: this.checkContentAndMedia
          }
        ],
        publish_time: [
          {
            trigger: 'blur',
            message: '不能小于当前时间',
            validator: this.hanleTimeDelay
          }
        ],
        risk_type: [
          {
            trigger: 'change',
            validator: this.checkContentRisk
          }
        ]
      },
      pickerOptions: pickerOptions,
      postTaskFormLoading: false,
      uploadFile: null,
      remark: ''
    }
  },
  created() {
    // this.setDefaultTime()
    this.getRiskKeywords()
  },
  methods: {
    hanleTimeDelay,
    getAfterTime,
    async postTaskFormSubmit() {
      await this.$refs.postTaskForm.validate(async valid => {
        if (valid) {
          this.postTaskFormLoading = true
          this.postTaskForm.account = this.accountId
          try {
            await addPostTask(this.postTaskForm)
            this.closePostTaskDialog()
          } catch (error) {
          } finally {
            this.postTaskFormLoading = false
          }
        } else {
          return false
        }
      })
    },
    closePostTaskDialog() {
      this.$refs.postTaskForm.resetFields()
      this.postTaskForm.publish_time = null
      this.postTaskDialogShow = false
      this.$emit('dialogStatus', false)
    },
    setDefaultTime() {
      this.postTaskForm.publish_time = getAfterTime(10 * 60)
    },
    checkContentAndMedia(rule, value, callback) {
      // rule 对应使用bargainMoney自定义验证的 对象
      // value 对应使用bargainMoney自定义验证的 数值
      // callback 回调函数
      if (!this.postTaskForm.content && !this.postTaskForm.media) {
        return callback(new Error('发帖内容 和 素材选择 不能同时为空'))
      } else if (this.postTaskForm.content) {
        if (!verifyTextLength(this.postTaskForm.content)) {
          return callback(new Error('长度不符合要求'))
        }
      }
      // this.$refs.postTaskForm.clearValidate()
      return callback()
    },
    checkContentRisk(rule, value, callback) {
      // 如果内容不为空，且该用户需要检测风险
      if (
        this.postTaskForm.content &&
        this.$store.state.user.userInfo.check_risk
      ) {
        // 检测风险关键词
        this.riskKeyword = ''
        this.riskKeywords.forEach(keyword => {
          if (this.postTaskForm.content.indexOf(keyword) !== -1) {
            this.riskKeyword = keyword
          }
        })
        if (this.riskKeyword === '') {
          this.riskSelectShow = false
          this.postTaskForm.risk_type = 0
        } else {
          this.riskSelectShow = true
          if (this.postTaskForm.risk_type === 0) {
            return callback(
              new Error(
                '内容包含风险词汇【' +
                  this.riskKeyword +
                  '】,请选择风险任务类型'
              )
            )
          }
        }
      } else {
        this.riskSelectShow = false
        this.postTaskForm.risk_type = 0
      }

      // this.$refs.postTaskForm.clearValidate()
      return callback()
    },
    getChildMaterial(material) {
      console.log('ooooooooo')
      console.log(material)
      this.postTaskForm.mediaMaterial = material
      this.postTaskForm.media = material.id
      // this.postTaskForm.mediaId = material.id
    },
    async getRiskKeywords() {
      const data = await getAllRiskKeyword()
      // console.log(data)
      this.riskKeywords = []
      data.forEach(element => {
        this.riskKeywords.push(element.keyword)
      })
    },
    // 语料
    languageFun(text) {
      this.postTaskForm.content = text
    },
    openTranslate() {
      this.$refs.translatePageRef.initFun()
    }
  },
  watch: {
    'postTaskForm.risk_type'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.postTaskForm.validate()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.post-task-media {
  width: 100%;
  display: flex;
}
</style>
